import { differenceInMinutes } from 'date-fns';

/**
 * A service for processing form responses in both individual and aggregate contexts
 */

/**
 * Normalizes a response value to handle case sensitivity and yes/no variations
 */
export const normalizeResponseValue = (value: any): any => {
  // Handle boolean responses
  if (typeof value === 'boolean') {
    return value ? 'yes' : 'no';
  }
  
  // Handle string responses
  if (typeof value === 'string') {
    // Convert to lowercase for case-insensitive comparisons
    const lowerValue = value.toLowerCase().trim();
    
    // Normalize yes/no/true/false variants
    if (lowerValue === 'yes' || lowerValue === 'true' || lowerValue === '1') {
      return 'yes';
    }
    if (lowerValue === 'no' || lowerValue === 'false' || lowerValue === '0') {
      return 'no';
    }
    
    // Return lowercase value for consistent case comparison
    return lowerValue;
  }
  
  // For other types, return as is
  return value;
};

/**
 * Normalizes text responses for comparison purposes
 * Returns a standardized version of the input for comparing text responses
 */
export const normalizeTextForComparison = (text: string): string => {
  if (!text || typeof text !== 'string') return '';
  
  // Convert to lowercase and trim whitespace
  return text.toLowerCase().trim()
    // Remove multiple spaces
    .replace(/\s+/g, ' ')
    // Remove common punctuation for comparison
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
};

/**
 * Checks if a response represents a truthy value (yes, true, etc.)
 */
export const isTruthyResponse = (response: any): boolean => {
  return normalizeResponseValue(response) === 'yes';
};

/**
 * Get all selected options from a response, handling different formats
 */
export const getSelectedOptions = (response: any): string[] => {
  if (Array.isArray(response)) {
    return response.map(normalizeResponseValue);
  } 
  
  if (typeof response === 'object' && response !== null) {
    return Object.entries(response)
      .filter(([_, value]) => value === true)
      .map(([key]) => normalizeResponseValue(key));
  }
  
  if (response !== null && response !== undefined) {
    return [normalizeResponseValue(response)];
  }
  
  return [];
};

/**
 * Normalize a set of text responses and group similar responses together
 * This function will group responses that are the same except for case differences
 */
export const normalizeAndGroupTextResponses = (responses: string[]): Record<string, string[]> => {
  const normalizedGroups: Record<string, string[]> = {};
  
  if (!responses || !Array.isArray(responses)) {
    console.warn("normalizeAndGroupTextResponses called with invalid input:", responses);
    return normalizedGroups;
  }
  
  responses.forEach(response => {
    if (!response && typeof response !== 'string') {
      console.warn("Invalid response item found:", response);
      return;
    }
    
    // Normalize for grouping - lowercase and remove extra spaces/punctuation
    const normalizedKey = normalizeTextForComparison(response);
    
    if (!normalizedGroups[normalizedKey]) {
      normalizedGroups[normalizedKey] = [];
    }
    
    // Add the original response with its original casing
    // Only add if not already in the array (avoid duplicates)
    if (!normalizedGroups[normalizedKey].includes(response)) {
      normalizedGroups[normalizedKey].push(response);
    }
  });
  
  // Debug to help identify issues
  console.log(`Grouped ${responses.length} responses into ${Object.keys(normalizedGroups).length} distinct groups`);
  
  return normalizedGroups;
};

/**
 * Calculate time duration from a time range response
 */
export const calculateDuration = (timeRangeResponse: any): number | null => {
  if (!timeRangeResponse || typeof timeRangeResponse !== 'object') {
    return null;
  }
  
  // If minutes are directly provided, use them
  if (typeof timeRangeResponse.minutes === 'number') {
    return timeRangeResponse.minutes;
  }
  
  // Calculate from start/end times
  if (timeRangeResponse.startTime && timeRangeResponse.endTime) {
    try {
      const startTime = new Date(timeRangeResponse.startTime);
      const endTime = new Date(timeRangeResponse.endTime);
      
      if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
        return differenceInMinutes(endTime, startTime);
      }
    } catch (err) {
      console.error("Error calculating duration:", err);
    }
  }
  
  return null;
};

/**
 * Get numeric value from a response, returning null if not a number
 */
export const getNumericValue = (response: any): number | null => {
  if (typeof response === 'number') {
    return response;
  }
  
  if (typeof response === 'string' && response.trim() !== '') {
    const num = Number(response);
    return !isNaN(num) ? num : null;
  }
  
  return null;
};