import { Question } from 'store/store';
import { normalizeTextForComparison } from '../services/responseProcessingService';
import { parseISO, isWithinInterval, startOfDay, endOfDay } from 'date-fns';

/**
 * Utility functions for filtering responses
 */

/**
 * Check if a text response matches a search term (case insensitive)
 */
export const textMatches = (text: any, searchTerm: string): boolean => {
  if (!text || !searchTerm) return false;
  
  const normalizedText = normalizeTextForComparison(String(text));
  const normalizedSearch = normalizeTextForComparison(searchTerm);
  
  return normalizedText.includes(normalizedSearch);
};

/**
 * Check if a submission should be included based on the filters
 */
export const filterSubmission = (
submission: any, filters: {
  searchTerm?: string;
  dateRange?: { start: Date | null; end: Date | null; };
  questionFilters?: Record<string, any>;
}, questions: Question[]): boolean => {
  // Skip empty filters
  if (!filters || Object.keys(filters).length === 0) return true;
  
  // Apply search term filter
  if (filters.searchTerm) {
    const searchTerm = filters.searchTerm.trim();
    if (searchTerm) {
      // Check if any response content or email contains the search term
      let matchesSearch = false;
      
      // Check email
      if (submission.email && textMatches(submission.email, searchTerm)) {
        matchesSearch = true;
      }
      
      // If not found in email, check all responses
      if (!matchesSearch && submission.responses) {
        for (const key in submission.responses) {
          const value = submission.responses[key];
          
          // Check if it's a string and contains the search term
          if (typeof value === 'string' && textMatches(value, searchTerm)) {
            matchesSearch = true;
            break;
          }
          
          // Check if it's an array of strings (like multi-select)
          if (Array.isArray(value)) {
            if (value.some(item => typeof item === 'string' && textMatches(item, searchTerm))) {
              matchesSearch = true;
              break;
            }
          }
          
          // Check if it's an object with text fields
          if (value && typeof value === 'object') {
            const valueString = JSON.stringify(value).toLowerCase();
            if (normalizeTextForComparison(valueString).includes(normalizeTextForComparison(searchTerm))) {
              matchesSearch = true;
              break;
            }
          }
        }
      }
      
      if (!matchesSearch) return false;
    }
  }
  
  // Apply date range filter
  if (filters.dateRange && (filters.dateRange.start || filters.dateRange.end)) {
    const { start, end } = filters.dateRange;
    
    // Get the submission date
    let submissionDate = null;
    
    // Handle various timestamp formats
    try {
      if (submission.submittedAt) {
        if (submission.submittedAt.toDate && typeof submission.submittedAt.toDate === 'function') {
          submissionDate = submission.submittedAt.toDate();
        } else if (submission.submittedAt.seconds !== undefined) {
          submissionDate = new Date(submission.submittedAt.seconds * 1000);
        } else {
          submissionDate = new Date(submission.submittedAt);
        }
      }
    } catch (e) {
      console.error("Error parsing submission date:", e);
    }
    
    // Apply date range filter if we have a valid submission date
    if (submissionDate && !isNaN(submissionDate.getTime())) {
      // Check start date
      if (start && !isNaN(start.getTime())) {
        if (submissionDate < startOfDay(start)) return false;
      }
      
      // Check end date
      if (end && !isNaN(end.getTime())) {
        if (submissionDate > endOfDay(end)) return false;
      }
    }
  }
  
  // Apply question-specific filters
  if (filters.questionFilters && Object.keys(filters.questionFilters).length > 0) {
    for (const questionId in filters.questionFilters) {
      const filterValue = filters.questionFilters[questionId];
      
      // Skip empty filters
      if (filterValue === '' || filterValue === null || 
          (Array.isArray(filterValue) && filterValue.length === 0)) {
        continue;
      }
      
      const response = submission.responses?.[questionId];
      
      // If the response doesn't exist, exclude the submission
      if (response === undefined || response === null) return false;
      
      // Handle different filter types
      if (typeof filterValue === 'string') {
        // Text filter - do case-insensitive contains check
        if (!textMatches(response, filterValue)) return false;
      }
      else if (Array.isArray(filterValue)) {
        // Multi-select filter - response must contain at least one selected value
        let matchFound = false;
        
        // Handle when response is an array of selected options
        if (Array.isArray(response)) {
          matchFound = response.some(item => 
            filterValue.some(filterItem => 
              normalizeTextForComparison(String(item)) === normalizeTextForComparison(filterItem)
            )
          );
        } 
        // Handle when response is an object with boolean values
        else if (typeof response === 'object' && response !== null) {
          matchFound = filterValue.some(filterItem => response[filterItem] === true);
        }
        // Handle single string response
        else if (typeof response === 'string') {
          matchFound = filterValue.some(filterItem => 
            normalizeTextForComparison(response) === normalizeTextForComparison(filterItem)
          );
        }
        
        if (!matchFound) return false;
      }
      else if (typeof filterValue === 'object' && filterValue !== null) {
        // Handle date range filters
        if (filterValue.start || filterValue.end) {
          let responseDate = null;
          
          // Parse the response date
          try {
            if (typeof response === 'string') {
              responseDate = parseISO(response);
            } else if (response instanceof Date) {
              responseDate = response;
            } else if (response && typeof response === 'object') {
              if (response.toDate && typeof response.toDate === 'function') {
                responseDate = response.toDate();
              } else if (response.seconds !== undefined) {
                responseDate = new Date(response.seconds * 1000);
              } else if (response.startDate) {
                // For date range questions, use start date for filtering
                if (typeof response.startDate === 'string') {
                  responseDate = parseISO(response.startDate);
                } else if (response.startDate instanceof Date) {
                  responseDate = response.startDate;
                } else if (response.startDate && typeof response.startDate === 'object') {
                  if (response.startDate.toDate && typeof response.startDate.toDate === 'function') {
                    responseDate = response.startDate.toDate();
                  } else if (response.startDate.seconds !== undefined) {
                    responseDate = new Date(response.startDate.seconds * 1000);
                  }
                }
              }
            }
          } catch (e) {
            console.error("Error parsing response date:", e);
          }
          
          // Apply date filter if we have a valid date
          if (responseDate && !isNaN(responseDate.getTime())) {
            if (filterValue.start && responseDate < startOfDay(filterValue.start)) return false;
            if (filterValue.end && responseDate > endOfDay(filterValue.end)) return false;
          } else {
            // If we couldn't parse a date, exclude this submission
            return false;
          }
        }
        
        // Handle number range filters
        if ((filterValue.min !== undefined && filterValue.min !== '') || 
            (filterValue.max !== undefined && filterValue.max !== '')) {
          const numValue = Number(response);
          
          if (isNaN(numValue)) return false;
          
          if (filterValue.min !== undefined && filterValue.min !== '' && numValue < Number(filterValue.min)) {
            return false;
          }
          
          if (filterValue.max !== undefined && filterValue.max !== '' && numValue > Number(filterValue.max)) {
            return false;
          }
        }
      }
    }
  }
  
  // If all filters passed, include this submission
  return true;
};