import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, Tab, Badge, Box, useTheme, useMediaQuery, Tooltip, IconButton } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import TabPanel from './TabPanel';
import { FormSubmissionCounts } from '../types';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

interface ApprovalTabsProps {
  counters: FormSubmissionCounts;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  children: React.ReactNode[];
  userId: string; // Add userId prop to track badge resets per user
}

const ApprovalTabs: React.FC<ApprovalTabsProps> = ({
  counters,
  value,
  onChange,
  children,
  userId
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Add state to track which counts have been acknowledged
  const [acknowledgedCounts, setAcknowledgedCounts] = useState<{
    approved: number;
    rejected: number;
  }>({
    approved: 0,
    rejected: 0
  });
  
  // Calculate total counts
  const getTotalCount = (status: 'pending' | 'approved' | 'rejected'): number => {
    return Object.values(counters).reduce((total, formCounts) => total + formCounts[status], 0);
  };

  const pendingCount = getTotalCount('pending');
  const approvedCount = getTotalCount('approved');
  const rejectedCount = getTotalCount('rejected');
  
  // Get the acknowledged (visible) counts
  const visibleApprovedCount = Math.max(0, approvedCount - acknowledgedCounts.approved);
  const visibleRejectedCount = Math.max(0, rejectedCount - acknowledgedCounts.rejected);
  
  // Load user's acknowledged counts from Firestore
  const loadUserAcknowledgedCounts = useCallback(async () => {
    if (!userId) return;
    
    try {
      const userAckRef = doc(db, 'user_acknowledgements', userId);
      const userAckDoc = await getDoc(userAckRef);
      
      if (userAckDoc.exists()) {
        const data = userAckDoc.data();
        setAcknowledgedCounts({
          approved: data.approvedCount || 0,
          rejected: data.rejectedCount || 0
        });
      } else {
        // Create new document for first-time users
        await setDoc(userAckRef, {
          approvedCount: 0,
          rejectedCount: 0,
          lastUpdated: new Date()
        });
      }
    } catch (error) {
      console.error('Error loading user acknowledged counts:', error);
    }
  }, [userId]);
  
  // Save user's acknowledged counts to Firestore - non-blocking version
  const saveUserAcknowledgedCounts = useCallback(async (counts: { approved: number; rejected: number }) => {
    
    if (!userId) {
      
      return;
    }
    
    // Use a non-blocking approach with setTimeout
    
    setTimeout(async () => {
      
      try {
        const userAckRef = doc(db, 'user_acknowledgements', userId);
        
        await updateDoc(userAckRef, {
          approvedCount: counts.approved,
          rejectedCount: counts.rejected,
          lastUpdated: new Date()
        });
        
      } catch (error) {
      
        // If document doesn't exist yet, create it
        try {
          const userAckRef = doc(db, 'user_acknowledgements', userId);
          await setDoc(userAckRef, {
            approvedCount: counts.approved,
            rejectedCount: counts.rejected,
            lastUpdated: new Date()
          });
            
        } catch (innerError) {
          console.error('Error saving user acknowledged counts:', innerError);
        }
      }
      
    }, 0);
    
  }, [userId]);
  
  // Reset counter for the current tab
  const handleResetCounter = useCallback((event: React.MouseEvent, tabType: 'approved' | 'rejected') => {
    event.stopPropagation();
    
    const newCounts = {
      ...acknowledgedCounts,
      [tabType]: tabType === 'approved' ? approvedCount : rejectedCount
    };
    
    setAcknowledgedCounts(newCounts);
    saveUserAcknowledgedCounts(newCounts);
  }, [acknowledgedCounts, approvedCount, rejectedCount, saveUserAcknowledgedCounts]);
  
  useEffect(() => {
    
    if (value === 1) { // Approved tab
      
      setAcknowledgedCounts(prev => {
        if (prev.approved !== approvedCount) {
          const newCounts = { ...prev, approved: approvedCount };
          saveUserAcknowledgedCounts(newCounts);
          return newCounts;
        }
        return prev;
      });
    } else if (value === 2) { // Rejected tab
      
      setAcknowledgedCounts(prev => {
        if (prev.rejected !== rejectedCount) {
          const newCounts = { ...prev, rejected: rejectedCount };
          saveUserAcknowledgedCounts(newCounts);
          return newCounts;
        }
        return prev;
      });
    }
  }, [value, saveUserAcknowledgedCounts]);
  
  // Load user's acknowledged counts on component mount - with isMounted guard
  useEffect(() => {
    let isMounted = true;
    
    // Use a non-blocking approach
    setTimeout(async () => {
      if (isMounted) {
        await loadUserAcknowledgedCounts();
      }
    }, 0);
    
    return () => {
      isMounted = false;
    };
  }, [loadUserAcknowledgedCounts]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={onChange}
          aria-label="approval tabs"
          variant={isMobile ? "fullWidth" : "standard"}
          sx={{
            // Add more space between tabs
            '& .MuiTabs-flexContainer': {
              gap: { xs: 1, sm: 3 }
            },
            // Ensure badges are visible 
            '& .MuiTab-root': {
              position: 'relative',
              paddingRight: { xs: 3, sm: 4 },
              minWidth: { xs: '33%', sm: 'auto' }
            }
          }}
        >
          <Tab 
            label={
              <Box sx={{ position: 'relative', pr: 3 }}>
                Pending
                <Badge 
                  badgeContent={pendingCount} 
                  color="warning" 
                  max={999}
                  sx={{ 
                    position: 'absolute',
                    top: '-8px',
                    right: '-12px',
                    '& .MuiBadge-badge': { 
                      fontSize: '0.7rem',
                      height: '18px',
                      minWidth: '18px',
                      padding: '0 4px'
                    }
                  }}
                />
              </Box>
            } 
            id="approval-tab-0"
            aria-controls="approval-tabpanel-0"
          />
          <Tab 
            label={
              <Box sx={{ position: 'relative', pr: 3 }}>
                Approved
                <Badge 
                  badgeContent={visibleApprovedCount > 0 ? visibleApprovedCount : null} 
                  color="success" 
                  max={999}
                  sx={{ 
                    position: 'absolute',
                    top: '-8px',
                    right: '-12px',
                    '& .MuiBadge-badge': { 
                      fontSize: '0.7rem',
                      height: '18px',
                      minWidth: '18px',
                      padding: '0 4px'
                    }
                  }}
                />
                {visibleApprovedCount > 0 && value !== 1 && (
                  <Tooltip title="Clear badge" placement="top">
                    <IconButton
                      onClick={(e) => handleResetCounter(e, 'approved')}
                      size="small"
                      sx={{ 
                        position: 'absolute',
                        right: -30,
                        top: -8,
                        padding: '2px',
                        opacity: 0.6,
                        '&:hover': {
                          opacity: 1,
                          backgroundColor: 'rgba(76, 175, 80, 0.1)'
                        }
                      }}
                    >
                      <Refresh fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            } 
            id="approval-tab-1"
            aria-controls="approval-tabpanel-1"
          />
          <Tab 
            label={
              <Box sx={{ position: 'relative', pr: 3 }}>
                Rejected
                <Badge 
                  badgeContent={visibleRejectedCount > 0 ? visibleRejectedCount : null} 
                  color="error" 
                  max={999}
                  sx={{ 
                    position: 'absolute',
                    top: '-8px',
                    right: '-12px',
                    '& .MuiBadge-badge': { 
                      fontSize: '0.7rem',
                      height: '18px',
                      minWidth: '18px',
                      padding: '0 4px'
                    }
                  }}
                />
                {visibleRejectedCount > 0 && value !== 2 && (
                  <Tooltip title="Clear badge" placement="top">
                    <IconButton
                      onClick={(e) => handleResetCounter(e, 'rejected')}
                      size="small"
                      sx={{ 
                        position: 'absolute',
                        right: -30,
                        top: -8,
                        padding: '2px',
                        opacity: 0.6,
                        '&:hover': {
                          opacity: 1,
                          backgroundColor: 'rgba(211, 47, 47, 0.1)'
                        }
                      }}
                    >
                      <Refresh fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            } 
            id="approval-tab-2"
            aria-controls="approval-tabpanel-2"
          />
        </Tabs>
      </Box>

      {children.map((child, index) => (
        <TabPanel key={index} value={value} index={index}>
          {child}
        </TabPanel>
      ))}
    </Box>
  );
};

export default ApprovalTabs;
