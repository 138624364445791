import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { 
  Box, Typography, Container, Paper, Tabs, Tab, Divider,
  Grid, Card, CardContent, Button, CircularProgress,
  Alert, AlertTitle, List, ThemeProvider, createTheme, Dialog, DialogTitle,
  DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, FormControlLabel, Switch
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { 
  BarChart, PieChart, FilterList as FilterIcon,
  Download as DownloadIcon, Refresh as RefreshIcon,
  ErrorOutline as ErrorOutlineIcon
} from '@mui/icons-material';
import { useUser } from "@clerk/clerk-react";
import { 
  getFormSubmissions, 
  getSubmissionDetails,
  FormSubmission
} from '../services/firebase/submissionReader';

import { 
  getFormResponseSummary, 
  getFormResponseSummaryNormalized 
} from '../services/firebase/summaryService';
import { useFormStore } from "../store/store";
import SharedNavBar from "../components/SharedNavBar";
import ResponseFilters from "../components/ResponseFilters";
import QuestionSummary from "../components/QuestionSummary";
import SubmissionDetail from "../components/SubmissionDetail/SubmissionDetail";
import { useExport } from "../hooks/useExport";
import { db } from "../firebase"; // Adjust the import path as needed
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { format } from 'date-fns';
import { themeOptions, createAppTheme } from '../theme/themeOptions';
import { filterSubmission } from '../utils/filterUtils';
/**
 * Safely formats a date value, handling various formats and invalid values
 * @param dateValue - The date value to format (timestamp, string, Date object, etc.)
 * @param formatString - format-fns format string (default: 'MMM d, yyyy')
 * @param fallback - Value to return if date is invalid (default: 'Invalid date')
 */
const safeFormatDate = (
  dateValue: any, 
  formatString: string = 'MMM d, yyyy', 
  fallback: string = 'Invalid date'
): string => {
  try {
    // Handle null/undefined 
    if (!dateValue) return fallback;
    
    // Handle Firestore Timestamp
    if (typeof dateValue === 'object' && dateValue !== null) {
      // Native Firestore timestamp
      if (typeof dateValue.toDate === 'function') {
        try {
          const dateObj = dateValue.toDate();
          if (!isNaN(dateObj.getTime())) {
            return format(dateObj, formatString);
          }
          return fallback;
        } catch (e) {
          console.error("Error converting Firestore timestamp:", e);
          return fallback;
        }
      }
      
      // Serialized Firestore timestamp
      if (dateValue.seconds !== undefined) {
        try {
          const milliseconds = dateValue.seconds * 1000 + (dateValue.nanoseconds || 0) / 1000000;
          const dateObj = new Date(milliseconds);
          if (!isNaN(dateObj.getTime())) {
            return format(dateObj, formatString);
          }
          return fallback;
        } catch (e) {
          console.error("Error parsing timestamp seconds:", e);
          return fallback;
        }
      }
      
      // Server timestamp not resolved yet
      if (dateValue._methodName === "serverTimestamp") {
        return 'Pending...';
      }

      // Object with toISOString method
      if (typeof dateValue.toISOString === 'function') {
        try {
          return format(dateValue, formatString);
        } catch (e) {
          console.error("Error formatting with toISOString:", e);
          return fallback;
        }
      }
    }
    
    // Try to parse as regular date
    try {
      const date = new Date(dateValue);
      if (!isNaN(date.getTime())) {
        return format(date, formatString);
      }
    } catch (e) {
      console.error("Error parsing as regular date:", e);
    }
    
    // If all else fails, return the fallback
    return fallback;
  } catch (error) {
    console.error('Error formatting date:', error, dateValue);
    return fallback;
  }
};

/**
 * Safely formats a time value
 * @param timeValue - Time value to format
 * @param fallback - Value to return if time is invalid
 */
const safeFormatTime = (timeValue: any, fallback: string = 'Invalid time'): string => {
  try {
    if (!timeValue) return fallback;
    
    // Handle time object with start/end times
    if (typeof timeValue === 'object' && timeValue !== null) {
      if (timeValue.startTime && timeValue.endTime) {
        const startTime = safeFormatDate(timeValue.startTime, 'HH:mm', 'Invalid');
        const endTime = safeFormatDate(timeValue.endTime, 'HH:mm', 'Invalid');
        
        if (startTime !== 'Invalid' && endTime !== 'Invalid') {
          return `${startTime} - ${endTime}`;
        }
      }
      
      // Handle Firestore timestamp
      if (typeof timeValue.toDate === 'function' || timeValue.seconds !== undefined) {
        return safeFormatDate(timeValue, 'HH:mm', fallback);
      }
    }
    
    // Try as regular time
    const date = new Date(timeValue);
    if (!isNaN(date.getTime())) {
      return format(date, 'HH:mm');
    }
    
    return fallback;
  } catch (error) {
    console.error('Error formatting time:', error, timeValue);
    return fallback;
  }
};

// Tab interface
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other }
    >
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

const ResponseDashboard: React.FC = () => {
  const { formId } = useParams();
  const navigate = useNavigate();
  const systemTheme = useTheme(); // Keep a reference to the system theme if needed
  const { user } = useUser();
  const { loadForm, questions, formTitle } = useFormStore();  
  
  // State variables - KEEP ALL STATE DECLARATIONS TOGETHER at the top
  const [themeColor, setThemeColor] = useState<string>("Blue");
  const [themeDialogOpen, setThemeDialogOpen] = useState(false);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [submissions, setSubmissions] = useState<any[]>([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState<any[]>([]);
  const [selectedSubmission, setSelectedSubmission] = useState<string | null>(null);
  const [submissionDetails, setSubmissionDetails] = useState<any>(null);
  const [summary, setSummary] = useState<any>({});
  const [filters, setFilters] = useState<any>({});
  const [availableForms, setAvailableForms] = useState<any[]>([]);
  const [includeArchived, setIncludeArchived] = useState(false);
  
  const { 
    exportSubmissionsToExcel,
    exporting 
  } = useExport();
  
  // Create theme based on selected color
  const selectedTheme = themeOptions.find(theme => theme.name === themeColor) || themeOptions[0];
  const theme = createTheme({
    palette: {
      primary: {
        main: selectedTheme.primary,
      },
      secondary: {
        main: selectedTheme.secondary,
      },
      background: {
        default: '#f5f5f5',
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: '0 2px 10px rgba(0,0,0,0.08)',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
            },
          },
        },
      },
    },
  });
  
  // Declare all function handlers AFTER all state declarations and BEFORE useEffect hooks
  
  // Handle tab changes
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  
  // Save theme preference when changed
  const handleThemeChange = (event: SelectChangeEvent) => {
    const newTheme = event.target.value;
    setThemeColor(newTheme);
    localStorage.setItem("userThemePreference", newTheme);
  };
  
  // View a specific submission
  const handleViewSubmission = async (submissionId: string) => {
    setSelectedSubmission(submissionId);
    setSubmissionDetails(null); // Reset while loading
    
    try {
      console.log(`Fetching details for submission ${submissionId}...`);
      const details = await getSubmissionDetails(submissionId);
      console.log("Received submission details:", details);
      
      if (!details) {
        console.warn("No details found for this submission");
        setSubmissionDetails({ id: submissionId, notFound: true });
      } else {
        setSubmissionDetails(details);
      }
    } catch (error) {
      console.error(`Error fetching details for submission ${submissionId}:`, error);
      setSubmissionDetails({ id: submissionId, error: true });
    }
  };
  
  // Handle filter changes
  const handleFilterChange = useCallback((newFilters: any) => {
    setFilters(newFilters);
  }, []);
  
  // Handle exports
  const handleExportCSV = () => {
    exportSubmissionsToExcel(formTitle, questions, filteredSubmissions);
  };
  
  const handleExportExcel = () => {
    exportSubmissionsToExcel(formTitle, questions, filteredSubmissions);
  };
  
  // IMPORTANT: Define the fetchData function before any useEffect hooks that call it
  async function fetchData() {
    setLoading(true);

    try {
      if (formId) {
        // Load form details
        await loadForm(formId);

        // Fetch all submissions for the form
        const allSubmissionData = await getFormSubmissions(formId);
        
        // Store all submissions in state
        setSubmissions(allSubmissionData);
        
        // Apply filters based on archive status
        const visibleSubmissions = includeArchived 
          ? allSubmissionData 
          : allSubmissionData.filter(sub => !sub.archived);
        
        console.log(`Showing ${visibleSubmissions.length} of ${allSubmissionData.length} submissions (includeArchived: ${includeArchived})`);
        
        setFilteredSubmissions(visibleSubmissions);
        
        // Update the selected submission if needed
        if (visibleSubmissions.length > 0) {
          // If the currently selected submission is now hidden, switch to another
          const currentStillVisible = visibleSubmissions.some(sub => sub.id === selectedSubmission);
          
          if (!currentStillVisible || !selectedSubmission) {
            const newSelectedId = visibleSubmissions[0]?.id || null;
            setSelectedSubmission(newSelectedId);
            if (newSelectedId) {
              const details = await getSubmissionDetails(newSelectedId);
              setSubmissionDetails(details);
            } else {
              setSubmissionDetails(null);
            }
          }
        } else {
          // No visible submissions
          setSelectedSubmission(null);
          setSubmissionDetails(null);
        }

        // Rest of the function remains the same
        const summaryData = await getFormResponseSummary(formId);
        setSummary(summaryData || {});
      } else {
        // Code for the forms overview page
        // ...existing code for fetching available forms
      }
    } catch (error) {
      console.error("Error loading response data:", error);
      setError("Failed to load response data. Please try again.");
    } finally {
      setLoading(false);
    }
  }
  
  // NOW place ALL useEffect hooks AFTER all function definitions and BEFORE any conditional returns
  
  // Load user theme preference from localStorage
  useEffect(() => {
    const savedTheme = localStorage.getItem("userThemePreference");
    if (savedTheme && themeOptions.some(theme => theme.name === savedTheme)) {
      setThemeColor(savedTheme);
    }
  }, []);
  
  // Update when includeArchived changes
  useEffect(() => {
    if (formId) {
      console.log(`includeArchived changed to ${includeArchived}, refreshing data...`);
      fetchData();
    }
  }, [formId, includeArchived]); // eslint-disable-line react-hooks/exhaustive-deps
  
  // Add effect to fetch user role
  useEffect(() => {
    const fetchUserRole = async () => {
      if (!user) {
        setUserRole(null);
        return;
      }
      
      try {
        const userDocRef = doc(db, "users", user.id);
        const userDocSnap = await getDoc(userDocRef);
        
        if (userDocSnap.exists()) {
          setUserRole(userDocSnap.data().role || null);
        } else {
          setUserRole(null);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
        setUserRole(null);
      }
    };
    
    fetchUserRole();
  }, [user]);
  
  // Load form and submissions
  useEffect(() => {
    fetchData();
  }, [formId, loadForm, user?.id, userRole]); // eslint-disable-line react-hooks/exhaustive-deps
  
  // We're using the imported filterSubmission function from filterUtils.ts
  // instead of the custom implementation
  
  // Apply filters to submissions
  useEffect(() => {
    const applyFilters = async () => {
      if (!submissions || submissions.length === 0) return;
      
      console.log("Applying filters:", filters);
      
      // If no filters are active, show all non-archived submissions (or all if includeArchived is true)
      if (!filters || Object.keys(filters).length === 0) {
        const baseSubmissions = includeArchived 
          ? submissions 
          : submissions.filter(sub => !sub.archived);
        setFilteredSubmissions(baseSubmissions);
        return;
      }
      
      // Apply filters to submissions using the filterSubmission utility
      const filtered = submissions
        .filter(submission => includeArchived || !submission.archived)
        .filter(submission => filterSubmission(submission, filters, questions));
      
      console.log(`Filtered ${submissions.length} submissions down to ${filtered.length}`);
      setFilteredSubmissions(filtered);
    };
    
    applyFilters();
  }, [filters, submissions, questions, includeArchived]); // includeArchived added to dependencies
  
  // IMPORTANT: Effect that recalculates summary data 
  useEffect(() => {
    if (!formId || filteredSubmissions.length === 0) return;
    
    console.log(`Recalculating summary data based on ${filteredSubmissions.length} filtered submissions`);
    
    // ...existing summary recalculation code
  }, [filteredSubmissions, questions, formId]); // eslint-disable-line react-hooks/exhaustive-deps
  
  // Update the renderSummaryContent function
  const renderSummaryContent = () => {
    if (formId && filteredSubmissions.length === 0) {
      return (
        <Box textAlign="center" py={4}>
          <Typography variant="h6" color="text.secondary">
            No submissions match your current filters
          </Typography>
          {filters && Object.keys(filters).length > 0 && (
            <Button 
              startIcon={<RefreshIcon />}
              variant="outlined" 
              sx={{ mt: 2 }}
              onClick={() => setFilters({})}
            >
              Clear Filters
            </Button>
          )}
        </Box>
      );
    }
  
    if (!formId) {
      return (
        <Typography>Please select a form to view responses</Typography>
      );
    }
  
    return (
      <Box>
        {questions
          .filter(q => q.type !== 'section')
          .map(question => (
            <QuestionSummary
              key={question.id}
              question={question}
              summaryData={summary[question.id] || {}}
              filteredSubmissions={filteredSubmissions}
              onFilterChange={(questionId, filterValue) => {
                // Update filters with the new filter value
                const newFilters = { ...filters };
                
                if (filterValue === null || filterValue === '' || 
                    (Array.isArray(filterValue) && filterValue.length === 0)) {
                  // Remove the filter if it's empty
                  delete newFilters[questionId];
                } else {
                  // Add or update the filter
                  newFilters[questionId] = filterValue;
                }
                
                // Apply the updated filters
                setFilters(newFilters);
              }}
              allQuestions={questions}
            />
          ))}
      </Box>
    );
  };
  
  // NOW you can use conditional returns
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
          <SharedNavBar onThemeClick={() => setThemeDialogOpen(true)} />
          <Container>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
              <CircularProgress color="primary" />
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh' }}>
          <SharedNavBar onThemeClick={() => setThemeDialogOpen(true)} />
          <Container>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
              <Alert severity="error" sx={{ width: '100%', maxWidth: '500px' }}>
                <AlertTitle>Error</AlertTitle>  {/* Properly closed here */}
                {error}
              </Alert>
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    );
  }

  if (!formId && !loading && !error) {

    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh', pb: 8 }}>
          <SharedNavBar onThemeClick={() => setThemeDialogOpen(true)} />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
            {/* Header */}
            <Paper 
              elevation={2} 
              sx={{ p: 3, mb: 4, borderRadius: 2, bgcolor: theme.palette.primary.main, color: 'white' }}
            >
              <Typography variant="h4" fontWeight="bold">Response Dashboards</Typography>
              <Typography variant="subtitle1">
                Select a form to view its response dashboard
              </Typography>
            </Paper>
            
            {/* Form List */}
            {availableForms.length === 0 ? (
              <Paper sx={{ p: 4, textAlign: 'center', borderRadius: 2 }}>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  No forms with responses
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  Once your forms receive responses, they will appear here.
                </Typography>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={() => navigate('/')}
                >
                  Go to My Forms
                </Button>
              </Paper>
            ) : (
              <Grid container spacing={3}>
                {availableForms.map((form) => (
                  <Grid item xs={12} sm={6} md={4} key={form.id}>
                    <Card 
                      sx={{ 
                        cursor: 'pointer',
                        height: '100%',
                        transition: 'transform 0.2s',
                        '&:hover': {
                          transform: 'translateY(-4px)',
                          boxShadow: 4
                        }
                      }}
                      onClick={() => navigate(`/responses/${form.id}`)}
                    >
                      <CardContent>
                        <Typography variant="h6" gutterBottom fontWeight="bold">
                          {form.formTitle || "Untitled Form"}
                        </Typography>
                        
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                          <BarChart color="primary" sx={{ mr: 1 }} />
                          <Typography variant="body1" fontWeight="medium" color="primary">
                            {form.responses} responses
                          </Typography>
                        </Box>
                        
                        <Typography variant="caption" color="text.secondary" display="block" sx={{ mt: 1 }}>
                          Created: {safeFormatDate(
                            form.createdAt,
                            'MMM d, yyyy',
                            'Recently'
                          )}
                        </Typography>
                      </CardContent>
                      <Box sx={{ p: 2, pt: 0 }}>
                        <Button 
                          variant="outlined"
                          fullWidth
                          color="primary"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/responses/${form.id}`);
                          }}
                        >
                          View Dashboard
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Container>
          
          {/* Theme Selection Dialog */}
          <Dialog
            open={themeDialogOpen}
            onClose={() => setThemeDialogOpen(false)}
            PaperProps={{
              sx: { borderRadius: '12px', maxWidth: 400 }
            }}
          >
            <DialogTitle>
              <Typography variant="h6" fontWeight="600">Customize Theme</Typography>
            </DialogTitle>
            <DialogContent>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel id="theme-select-label">Choose Theme</InputLabel>
                <Select
                  labelId="theme-select-label"
                  value={themeColor}
                  label="Choose Theme"
                  onChange={handleThemeChange}
                >
                  {themeOptions.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box 
                          sx={{ 
                            width: 24, 
                            height: 24, 
                            borderRadius: '50%', 
                            bgcolor: option.primary,
                            mr: 2
                          }} 
                        />
                        {option.name}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setThemeDialogOpen(false)} variant="outlined">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: theme.palette.background.default, minHeight: '100vh', pb: 8 }}>
        <SharedNavBar onThemeClick={() => setThemeDialogOpen(true)} />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 8 }}>
          {/* Header Section */}
          <Paper 
            elevation={2} 
            sx={{ p: 3, mb: 4, borderRadius: 2, bgcolor: theme.palette.primary.main, color: 'white' }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <div>
                <Typography variant="h4" fontWeight="bold">{formTitle || "Form Responses"}</Typography>
                <Typography variant="subtitle1">
                  {filteredSubmissions.length} of {submissions.length} responses
                  {filteredSubmissions.length !== submissions.length && ' (filtered)'}
                </Typography>
              </div>
              <Box>
                <Button 
                  startIcon={<DownloadIcon />} 
                  variant="contained" 
                  color="secondary"
                  onClick={handleExportCSV}
                  disabled={exporting || filteredSubmissions.length === 0}
                  sx={{ mr: 1 }}
                >
                  Export CSV
                </Button>
                <Button 
                  startIcon={<DownloadIcon />} 
                  variant="contained" 
                  color="secondary"
                  onClick={handleExportExcel}
                  disabled={exporting || filteredSubmissions.length === 0}
                >
                  Export Excel
                </Button>
              </Box>
            </Box>
          </Paper>

          {/* Filters Section */}
          <ResponseFilters 
            onFilterChange={handleFilterChange}
            questions={questions}
          />

          {/* Tabs Navigation */}
          <Paper sx={{ mb: 4, borderRadius: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs 
                value={tabValue} 
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Summary" icon={<BarChart />} iconPosition="start" />
                <Tab label="Individual Responses" icon={<FilterIcon />} iconPosition="start" />
              </Tabs>
            </Box>

            {/* Summary Tab Panel */}
            <TabPanel value={tabValue} index={0}>
              <Box p={3}>
                {renderSummaryContent()}
              </Box>
            </TabPanel>

            {/* Individual Responses Tab Panel */}
            <TabPanel value={tabValue} index={1}>
              <Box p={3}>
                {filteredSubmissions.length === 0 ? (
                  <Typography variant="h6" textAlign="center">No submissions for this form yet</Typography>
                ) : (
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={3}>
                        {/* Submission List */}
                        <Paper elevation={1} sx={{ p: 2, borderRadius: 2, height: '70vh', overflow: 'auto' }}>
                          <Box sx={{ mb: 2 }}>
                            <Typography variant="h6" fontWeight="bold" gutterBottom>
                              Submissions
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={includeArchived}
                                    onChange={() => {
                                      setIncludeArchived(prev => !prev);
                                      // No need for an explicit fetchData call here since useEffect will call it
                                    }}
                                    color="primary"
                                    size="small"
                                  />
                                }
                                label="Include archived"
                                // Remove margin
                                sx={{ m: 0 }}
                              />
                              <Typography variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                {filteredSubmissions.filter(sub => !sub.archived).length} active
                                {includeArchived && filteredSubmissions.some(sub => sub.archived) && 
                                  `, ${filteredSubmissions.filter(sub => sub.archived).length} archived`}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider sx={{ mb: 2 }} />
                          
                          <List sx={{ overflow: 'auto', height: '70vh' }}>
                            {filteredSubmissions.length > 0 ? (
                              filteredSubmissions
                                .filter(submission => includeArchived || !submission.archived) // Add this filter
                                .map((submission) => (
                                  <Card 
                                    key={submission.id} 
                                    sx={{ 
                                      mb: 1.5, 
                                      cursor: 'pointer',
                                      bgcolor: submission.archived 
                                        ? `rgba(0,0,0,0.05)` 
                                        : selectedSubmission === submission.id 
                                          ? `${theme.palette.primary.main}15` 
                                          : 'white',
                                      borderLeft: selectedSubmission === submission.id 
                                        ? `4px solid ${theme.palette.primary.main}` 
                                        : submission.archived 
                                          ? `4px solid ${theme.palette.text.disabled}` 
                                          : 'none',
                                      transition: 'all 0.2s',
                                      opacity: submission.archived ? 0.8 : 1
                                    }}
                                    onClick={() => handleViewSubmission(submission.id)}
                                  >
                                    <CardContent sx={{ py: 1.5, '&:last-child': { pb: 1.5 } }}>
                                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="subtitle2" sx={{ 
                                          color: submission.archived ? 'text.secondary' : 'text.primary' 
                                        }}>
                                          {submission.email || "Anonymous"}
                                        </Typography>
                                        {submission.archived && (
                                          <Typography 
                                            variant="caption" 
                                            sx={{ 
                                              bgcolor: 'grey.200', 
                                              px: 1, 
                                              py: 0.2, 
                                              borderRadius: 1, 
                                              color: 'text.secondary',
                                              fontSize: '0.7rem'
                                            }}
                                          >
                                            Archived
                                          </Typography>
                                        )}
                                      </Box>
                                      <Typography variant="caption" color="text.secondary">
                                        {safeFormatDate(submission.submittedAt, 'MMM d, yyyy HH:mm')}
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                ))
                            ) : (
                              <Box textAlign="center" mt={3}>
                                <Typography color="text.secondary">
                                  No submissions match your current filters
                                </Typography>
                              </Box>
                            )}
                          </List>
                        </Paper>
                      </Grid>
                      
                      <Grid item xs={12} md={9}>
                        <Paper elevation={1} sx={{ p: 3, borderRadius: 2, height: '70vh', overflow: 'auto' }}>
                          {selectedSubmission ? (
                            <SubmissionDetail
                              submission={submissionDetails}
                              questions={questions}
                              formId={formId || ''}
                              formTitle={formTitle}
                              theme={theme}
                              onArchiveChange={async () => {
                                // If we have a selected submission, refresh its details
                                if (selectedSubmission) {
                                  const updatedDetails = await getSubmissionDetails(selectedSubmission);
                                  setSubmissionDetails(updatedDetails);
                                }
                                
                                // Refresh all data to reflect the new archive status
                                await fetchData();
                              }}
                            />
                          ) : (
                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                              <Typography variant="h6" color="text.secondary">
                                Select a submission to view details
                              </Typography>
                            </Box>
                          )}
                        </Paper>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
            </TabPanel>
          </Paper>
        </Container>

        {/* Theme Selection Dialog */}
        <Dialog
          open={themeDialogOpen}
          onClose={() => setThemeDialogOpen(false)}
          PaperProps={{
            sx: { borderRadius: '12px', maxWidth: 400 }
          }}
        >
          <DialogTitle>
            <Typography variant="h6" fontWeight="600">Customize Theme</Typography>
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="theme-select-label">Choose Theme</InputLabel>
              <Select
                labelId="theme-select-label"
                value={themeColor}
                label="Choose Theme"
                onChange={handleThemeChange}
              >
                {themeOptions.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box 
                        sx={{ 
                          width: 24, 
                          height: 24, 
                          borderRadius: '50%', 
                          bgcolor: option.primary,
                          mr: 2
                        }} 
                      />
                      {option.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setThemeDialogOpen(false)} variant="outlined">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default ResponseDashboard;
