import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, Container, Typography, Paper, Button, 
  CircularProgress, Badge, IconButton, Tooltip, Alert, 
  useMediaQuery, ThemeProvider, SelectChangeEvent,
  Dialog, DialogTitle, DialogContent, IconButton as MuiIconButton,
  ToggleButtonGroup, ToggleButton
} from '@mui/material';
import { Class, Refresh, Close, FormatListNumbered, ViewList } from '@mui/icons-material';
import { 
  collection, query, where, getDocs, doc, 
  getDoc, writeBatch
} from 'firebase/firestore';
import { db } from '../firebase';
import SharedNavBar from '../components/SharedNavBar';
import { formatDistanceToNow } from 'date-fns';
import { useUserPreferences } from '../hooks/useUserPreferences';
import { createAppTheme } from '../theme/themeOptions';
import SubmissionDetail from '../components/SubmissionDetail/SubmissionDetail';
import FixApprovalData from '../components/AdminTools/FixApprovalData';

// Import our approval service
import { 
  getApprovalsForUser, 
  getApprovalFormSummary, 
  updateApprovalStatus, 
  getApprovalSubmissionDetails,
  getApprovalSubmissionsForForm
} from '../services/firebase/approvalService';

// Import our custom components
import { AdminUserFilter } from '../components/approval/ApprovalFilters';
import { ApprovalDialog, RejectionDialog } from '../components/approval/ApprovalDialogs';
import { sendApprovalNotification } from '../components/approval/services/approvalNotifications';
import { formatDate } from '../components/approval/services/approvalUtils';
import { 
  GroupedSubmissions, 
  PaginationState, 
  FormSubmissionCounts 
} from '../components/approval/types';

// Importing the submission list components
import ApprovalSubmissionsList from '../components/approval/ApprovalList/ApprovalSubmissionList';
import ApprovalSnackbar from '../components/approval/common/ApprovalSnackbar';
import ApprovalTabs from '../components/approval/ApprovalTabs/ApprovalTabs';

// Add this CSS for the refresh animation
const styleElement = document.createElement('style');
styleElement.textContent = `
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
document.head.appendChild(styleElement);

const ApprovalManager: React.FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  
  // Wrap the navigate function to add debugging
  const debugNavigate = useCallback((path: string) => {
    console.log(`ApprovalManager: Navigating to ${path}`);
    navigate(path);
    console.log(`ApprovalManager: navigate('${path}') called`);
  }, [navigate]);
  
  // Theme setup
  const { theme: themePreference } = useUserPreferences();
  const theme = createAppTheme(themePreference || 'Blue');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // State variables
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [groupedSubmissions, setGroupedSubmissions] = useState<GroupedSubmissions[]>([]);
  const [tabValue, setTabValue] = useState(0);
  const [expandedFormId, setExpandedFormId] = useState<string | false>(false);
  
  // Selected submission and approval dialog state
  const [selectedSubmission, setSelectedSubmission] = useState<any>(null);
  const [approvalDialogOpen, setApprovalDialogOpen] = useState(false);
  const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const [comments, setComments] = useState('');
  const [processingAction, setProcessingAction] = useState(false);
  const [actionError, setActionError] = useState<string | null>(null);
  const [actionSuccess, setActionSuccess] = useState<string | null>(null);

  // Pagination state
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: 0,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0
  });

  // Submission detail dialog state
  const [showSubmissionDetail, setShowSubmissionDetail] = useState(false);
  const [submissionDetails, setSubmissionDetails] = useState<any>(null);
  const [questions, setQuestions] = useState<any[]>([]);

  // For admin filtering
  const [users, setUsers] = useState<Array<{id: string, email: string, name: string}>>([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const [userRole, setUserRole] = useState<string | null>(null);

  // Form submission counts
  const [formSubmissionCounts, setFormSubmissionCounts] = useState<FormSubmissionCounts>({});

  // Snackbar state
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error' | 'info' | 'warning';
  }>({
    open: false,
    message: '',
    severity: 'info'
  });

  // State for view mode
  const [viewMode, setViewMode] = useState<'limited' | 'all'>('limited');

  // Helper to show snackbar
  const showSnackbar = (message: string, severity: 'success' | 'error' | 'info' | 'warning') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  // Close snackbar
  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };

  // Fetch user role
  useEffect(() => {
    const fetchUserRole = async () => {
      if (!user?.id) return;
      
      try {
        const userRef = doc(db, "users", user.id);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          setUserRole(userSnap.data().role);
        } else {
          console.warn("No role found for user in Firestore.");
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, [user]);

  // Fetch all users if current user is an admin
  useEffect(() => {
    if (userRole === "Admin") {
      fetchUsers();
    }
  }, [userRole]);

  // Fetch users for admin filtering
  const fetchUsers = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, "users"));
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        email: doc.data().email || "No email",
        name: doc.data().firstName && doc.data().lastName ? 
          `${doc.data().firstName} ${doc.data().lastName}` : 
          doc.data().firstName || doc.data().email || doc.id
      }));
      
      // Sort users alphabetically by name
      usersData.sort((a, b) => a.name.localeCompare(b.name));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // Create a memoized loadSubmissions function WITHOUT expandedFormId dependency
  const loadSubmissions = useCallback(async (resetPagination: boolean = false, isRefresh: boolean = false) => {
    if (!user) return;
    
    try {
      if (isRefresh) {
        setRefreshing(true);
      } else {
        setLoading(true);
      }
      
      setActionError(null);
      setActionSuccess(null);
      
      // Status to filter by based on active tab
      const approvalStatus = 
        tabValue === 0 ? "pending" : 
        tabValue === 1 ? "approved" : "rejected";
      
      // Reset pagination if needed
      if (resetPagination) {
        setPaginationState(prev => ({
          ...prev,
          page: 0
        }));
      }
      
      // Get form summary first to get the counts
      console.log(`Getting form summary for user ${user.id}, admin: ${userRole === "Admin"}, filter: ${selectedUserId || "none"}`);
      const formSummary = await getApprovalFormSummary(
        user?.id || '',
        userRole === "Admin",
        userRole === "Admin" ? selectedUserId || null : null
      );
      
      console.log(`Got form summary with ${formSummary.length} forms`);
      
      // Build counts map from summary
      const countsMap: FormSubmissionCounts = {};
      formSummary.forEach(form => {
        countsMap[form.formId] = {
          pending: form.pendingCount,
          approved: form.approvedCount,
          rejected: form.rejectedCount
        };
        console.log(`Form ${form.formId} counts: pending=${form.pendingCount}, approved=${form.approvedCount}, rejected=${form.rejectedCount}`);
      });
      
      setFormSubmissionCounts(countsMap);
      
      // Calculate total items for pagination - only count relevant items based on current tab
      const totalItems = formSummary.reduce((sum, form) => {
        if (approvalStatus === 'pending') return sum + form.pendingCount;
        if (approvalStatus === 'approved') return sum + form.approvedCount;
        return sum + form.rejectedCount;
      }, 0);
      
      console.log(`Total ${approvalStatus} items: ${totalItems}`);
      
      // Update pagination state
      setPaginationState(prev => ({
        ...prev,
        totalItems,
        totalPages: Math.ceil(totalItems / prev.pageSize)
      }));
      
      const currentPage = resetPagination ? 0 : paginationState.page;
      
      // Set a large page size to get all submissions
      const pageSize = 1000; // Large enough to get all submissions
      
      // Get the actual submissions without pagination
      console.log(`Getting all approvals for status ${approvalStatus}`);
      const { submissions } = await getApprovalsForUser(
        user?.id || '',
        approvalStatus as 'pending' | 'approved' | 'rejected',
        userRole === "Admin",
        userRole === "Admin" ? selectedUserId || null : null,
        pageSize,
        0 // Always get the first page
      );
      
      console.log(`Got ${submissions.length} submissions`);
      
      // Group submissions by form
      const submissionsByForm: Record<string, any[]> = {};
      
      submissions.forEach(submission => {
        const formId = submission.formId;
        if (!submissionsByForm[formId]) {
          submissionsByForm[formId] = [];
        }
        submissionsByForm[formId].push(submission);
      });
      
      // Create the grouped submissions array
      const grouped: GroupedSubmissions[] = Object.keys(submissionsByForm).map(formId => {
        const formSubmissions = submissionsByForm[formId];
        const firstSubmission = formSubmissions[0]; // For getting form details
        
        return {
          formId,
          formTitle: firstSubmission.formTitle || 'Untitled Form',
          formLogo: firstSubmission.formLogo,
          userId: firstSubmission.userId || user.id,
          totalCount: countsMap[formId] ? 
            (approvalStatus === 'pending' ? countsMap[formId].pending : 
             approvalStatus === 'approved' ? countsMap[formId].approved : 
             countsMap[formId].rejected) : formSubmissions.length,
          pendingCount: countsMap[formId] ? countsMap[formId].pending : 0,
          submissions: formSubmissions
        };
      });
      
      // Sort forms by those with most submissions first
      grouped.sort((a, b) => b.totalCount - a.totalCount);
      
      console.log(`Grouped into ${grouped.length} forms`);
      
      setGroupedSubmissions(grouped);
      
      // If there's only one form, expand it automatically only if we're not in a refresh operation
      if (grouped.length === 1 && !expandedFormId && !isRefresh) {
        setExpandedFormId(grouped[0].formId);
      }
    } catch (error) {
      console.error("Error loading submissions:", error);
      setActionError("Failed to load submissions. Please try again.");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  }, [user, userRole, selectedUserId, tabValue, paginationState.page, paginationState.pageSize, viewMode]);

  // Handle user selection changes for admin filtering
  const handleUserChange = (event: SelectChangeEvent<string>) => {
    setSelectedUserId(event.target.value);
    setExpandedFormId(false); // Close any open accordions
    setPaginationState(prev => ({ ...prev, page: 0 })); // Reset to first page
  };

  // Reload submissions when selectedUserId changes
  useEffect(() => {
    if (user && selectedUserId !== undefined) {
      console.log("Selected user ID changed:", selectedUserId);
      loadSubmissions(true);
    }
  }, [selectedUserId, loadSubmissions]);

  // Handle page changes
  useEffect(() => {
    if (user) {
      console.log("Page changed:", paginationState.page);
      loadSubmissions(false);
    }
  }, [paginationState.page, loadSubmissions]);

  // Load initial submissions when component mounts
  useEffect(() => {
    if (user) {
      console.log("Initial load");
      loadSubmissions(true);
    }
  }, [user, loadSubmissions]);

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log(`Tab changed from ${tabValue} to ${newValue}`);
    setTabValue(newValue);
    setPaginationState(prev => ({ ...prev, page: 0 })); // Reset to first page
    loadSubmissions(true);
  };

  // Manual refresh button handler
  const handleRefresh = () => {
    console.log("Manual refresh requested");
    loadSubmissions(false, true);
  };

  // Handle expanding/collapsing form sections
  const handleExpandForm = useCallback((formId: string) => {
    setExpandedFormId(prevId => prevId === formId ? false : formId);
  }, []);

  // View submission details
  const handleViewSubmissionDetails = async (submissionId: string) => {
    try {
      setProcessingAction(true);
      
      // Use our new service to get details
      console.log(`Getting submission details for ${submissionId}`);
      const details = await getApprovalSubmissionDetails(submissionId);
      
      // Update state and show dialog
      setSubmissionDetails(details.submission);
      setQuestions(details.questions);
      setShowSubmissionDetail(true);
      
    } catch (error) {
      console.error("Error loading submission details:", error);
      setActionError("Failed to load submission details");
    } finally {
      setProcessingAction(false);
    }
  };

  const handleCloseSubmissionDetail = () => {
    setShowSubmissionDetail(false);
    setSubmissionDetails(null);
  };

  // Open approval dialog
  const handleOpenApprovalDialog = (submission: any) => {
    setSelectedSubmission(submission);
    setComments('');
    setApprovalDialogOpen(true);
  };
  
  // Open rejection dialog
  const handleOpenRejectionDialog = (submission: any) => {
    setSelectedSubmission(submission);
    setComments('');
    setRejectionDialogOpen(true);
  };

  // Process submission approval (modified to show snackbar)
  const handleApproveSubmission = async () => {
    if (!selectedSubmission) return;
    
    try {
      setProcessingAction(true);
      setActionError(null);
      
      // Use our service to update approval status
      console.log(`Approving submission ${selectedSubmission.id}`);
      const success = await updateApprovalStatus(
        selectedSubmission.id, 
        'approved', 
        comments
      );
      
      if (!success) {
        setActionError("Failed to approve submission. Please try again.");
        return;
      }
      
      // Send email notification
      const emailSent = await sendApprovalNotification(
        selectedSubmission,
        'approved',
        comments
      );
      
      // Show success message
      setActionSuccess(
        `Submission approved successfully!`
      );
      
      // Show email status in snackbar
      if (emailSent) {
        showSnackbar(`Email notification sent to ${selectedSubmission.email}`, 'success');
      } else {
        showSnackbar('Submission approved, but notification email could not be sent', 'warning');
      }
      
      // Close dialog and refresh data
      setApprovalDialogOpen(false);
      setSelectedSubmission(null);
      setComments('');
      await loadSubmissions(false, true);
      
    } catch (error) {
      console.error("Error approving submission:", error);
      setActionError("Failed to approve submission. Please try again.");
    } finally {
      setProcessingAction(false);
    }
  };

  // Process submission rejection (modified to show snackbar)
  const handleRejectSubmission = async () => {
    if (!selectedSubmission || !comments.trim()) return;
    
    try {
      setProcessingAction(true);
      setActionError(null);
      
      // Use our service to update approval status
      console.log(`Rejecting submission ${selectedSubmission.id}`);
      const success = await updateApprovalStatus(
        selectedSubmission.id, 
        'rejected', 
        comments
      );
      
      if (!success) {
        setActionError("Failed to reject submission. Please try again.");
        return;
      }
      
      // Send email notification
      const emailSent = await sendApprovalNotification(
        selectedSubmission,
        'rejected',
        comments
      );
      
      // Show success message
      setActionSuccess(
        `Submission rejected successfully.`
      );
      
      // Show email status in snackbar
      if (emailSent) {
        showSnackbar(`Email notification sent to ${selectedSubmission.email}`, 'success');
      } else {
        showSnackbar('Submission rejected, but notification email could not be sent', 'warning');
      }
      
      // Close dialog and refresh data
      setRejectionDialogOpen(false);
      setSelectedSubmission(null);
      setComments('');
      await loadSubmissions(false, true);
      
    } catch (error) {
      console.error("Error rejecting submission:", error);
      setActionError("Failed to reject submission. Please try again.");
    } finally {
      setProcessingAction(false);
    }
  };

  // New function to handle form-level pagination
  const handleFormPageChange = useCallback((formId: string, page: number) => {
    console.log(`Loading page ${page} for form ${formId}`);
    
    // Get current approval status
    const approvalStatus = 
      tabValue === 0 ? "pending" : 
      tabValue === 1 ? "approved" : "rejected";
    
    // Set loading state for better UX
    setRefreshing(true);
    
    // Use your new service function
    getApprovalSubmissionsForForm(
      user?.id || '',
      formId,
      approvalStatus as 'pending' | 'approved' | 'rejected',
      userRole === "Admin",
      userRole === "Admin" ? selectedUserId || null : null,
      10, // pageSize
      page - 1 // Convert from 1-based to 0-based
    )
      .then(submissions => {
        // Update just this form's submissions in the groupedSubmissions state
        setGroupedSubmissions(prev => {
          return prev.map(group => {
            if (group.formId === formId) {
              return {
                ...group,
                submissions
              };
            }
            return group;
          });
        });
      })
      .catch(error => {
        console.error("Error loading form submissions:", error);
        setActionError(`Failed to load page ${page} for form ${formId}`);
      })
      .finally(() => {
        setRefreshing(false);
      });
  }, [user, tabValue, userRole, selectedUserId]);

  // Handle view mode change
  const handleViewModeChange = useCallback((_event: React.MouseEvent<HTMLElement>, newMode: 'limited' | 'all' | null) => {
    if (newMode !== null) {
      setViewMode(newMode);
      setPaginationState(prev => ({ ...prev, page: 0 })); // Reset pagination
      loadSubmissions(true); // Reload with new view mode
    }
  }, [loadSubmissions]);
  
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: theme.palette.background.default, minHeight: '100vh' }}>
        <SharedNavBar title="Approval Manager" />
        
        <Container maxWidth="lg" sx={{ mt: { xs: 2, sm: 4 }, mb: 8 }}>
          {/* Header Section */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: { xs: 2, sm: 3 }, 
              mb: { xs: 2, sm: 3 }, 
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              background: `linear-gradient(to right, ${theme.palette.background.paper}, ${theme.palette.background.default})`
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <Typography variant="h4" gutterBottom>
                  Manage Form Approvals
                </Typography>
                <Typography color="text.secondary">
                  Review and manage submissions that require your approval.
                </Typography>
              </div>
              
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* View Mode Toggle - Only show for approved/rejected tabs */}
                {(tabValue === 1 || tabValue === 2) && (
                  <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                    aria-label="view mode"
                    size="small"
                    sx={{ mr: 2 }}
                  >
                    <ToggleButton value="limited" aria-label="limited view">
                      <Tooltip title="Show latest 20 per form">
                        <FormatListNumbered fontSize="small" />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="all" aria-label="all view">
                      <Tooltip title="Show all submissions">
                        <ViewList fontSize="small" />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                
                <IconButton 
                  onClick={handleRefresh} 
                  disabled={loading || refreshing}
                  color="primary"
                  size="large"
                  sx={{ ml: 2 }}
                >
                  <Refresh sx={{ animation: refreshing ? 'spin 1s linear infinite' : 'none' }} />
                </IconButton>
              </Box>
            </Box>
          </Paper>

          {/* Admin Filter - Only show to admins */}
          {userRole === "Admin" && (
            <AdminUserFilter 
              users={users}
              selectedUserId={selectedUserId}
              onUserChange={handleUserChange}
            />
          )}

          {/* Admin Tools Section - Only visible to admins */}
          {userRole === "Admin" && (
            <FixApprovalData isAdmin={userRole === "Admin"} />
          )}
          
          {/* Success/Error Messages */}
          {actionError && (
            <Alert 
              severity="error" 
              sx={{ mb: 2 }}
              onClose={() => setActionError(null)}
            >
              {actionError}
            </Alert>
          )}
          
          {actionSuccess && (
            <Alert 
              severity="success" 
              sx={{ mb: 2 }}
              onClose={() => setActionSuccess(null)}
            >
              {actionSuccess}
            </Alert>
          )}
          
          {/* Tabs Section */}
          <Paper 
            elevation={0}
            sx={{ 
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider',
              overflow: 'hidden'
            }}
          >
            <ApprovalTabs 
              counters={formSubmissionCounts}
              value={tabValue}
              onChange={handleTabChange}
              userId={user?.id || ''} // Add this line to pass user ID
            >
              {/* Pending Tab Content */}
              <ApprovalSubmissionsList
                status="pending" // Make sure this is explicitly set
                groupedSubmissions={groupedSubmissions}
                loading={loading}
                refreshing={refreshing}
                expandedFormId={expandedFormId}
                onExpandForm={handleExpandForm}
                onViewDetails={handleViewSubmissionDetails}
                onApprove={handleOpenApprovalDialog}
                onReject={handleOpenRejectionDialog}
                onLoadMore={handleFormPageChange}
                navigate={debugNavigate}
                theme={theme}
                isMobile={isMobile}
                users={users}
                selectedUserId={selectedUserId}
                userRole={userRole}
              />
              
              {/* Approved Tab Content */}
              <ApprovalSubmissionsList
                status="approved" // Make sure this is explicitly set
                groupedSubmissions={groupedSubmissions}
                loading={loading}
                refreshing={refreshing}
                expandedFormId={expandedFormId}
                onExpandForm={handleExpandForm}
                onViewDetails={handleViewSubmissionDetails}
                onLoadMore={handleFormPageChange}
                navigate={debugNavigate}
                theme={theme}
                isMobile={isMobile}
                users={users}
                selectedUserId={selectedUserId}
                userRole={userRole}
              />
              
              {/* Rejected Tab Content */}
              <ApprovalSubmissionsList
                status="rejected" // Make sure this is explicitly set
                groupedSubmissions={groupedSubmissions}
                loading={loading}
                refreshing={refreshing}
                expandedFormId={expandedFormId}
                onExpandForm={handleExpandForm}
                onViewDetails={handleViewSubmissionDetails}
                onLoadMore={handleFormPageChange}
                navigate={navigate}
                theme={theme}
                isMobile={isMobile}
                users={users}
                selectedUserId={selectedUserId}
                userRole={userRole}
              />
            </ApprovalTabs>
          </Paper>
        </Container>
        
        {/* Submission Detail Dialog */}
        <Dialog
          open={showSubmissionDetail}
          onClose={handleCloseSubmissionDetail}
          maxWidth="lg"
          fullWidth
          fullScreen={isMobile}
          PaperProps={{
            sx: isMobile ? {
              margin: 0,
              maxHeight: '100vh',
              borderRadius: 0
            } : { 
              borderRadius: { xs: '8px', sm: '12px' }, 
              height: { xs: '95vh', sm: '90vh' },
              width: { xs: '95%', sm: 'auto' },
              margin: { xs: '8px', sm: 'auto' }
            }
          }}
        >
          <DialogTitle sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: 'divider',
            p: 2
          }}>
            <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}>
              Submission Details
            </Typography>
            <MuiIconButton onClick={handleCloseSubmissionDetail} size="small">
              <Close fontSize="small" />
            </MuiIconButton>
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            {submissionDetails && (
              <SubmissionDetail
                submission={submissionDetails}
                questions={questions}
                formId={submissionDetails.formId || ''}
                formTitle={submissionDetails.formTitle || 'Untitled Form'}
                theme={theme}
                onArchiveChange={() => {
                  handleCloseSubmissionDetail();
                  loadSubmissions(true);
                }}
              />
            )}
          </DialogContent>
        </Dialog>
        
        {/* Approval Dialog */}
        <ApprovalDialog
          open={approvalDialogOpen}
          onClose={() => setApprovalDialogOpen(false)}
          onApprove={handleApproveSubmission}
          selectedSubmission={selectedSubmission}
          comments={comments}
          onCommentsChange={setComments}
          processingAction={processingAction}
        />
        
        {/* Rejection Dialog */}
        <RejectionDialog
          open={rejectionDialogOpen}
          onClose={() => setRejectionDialogOpen(false)}
          onReject={handleRejectSubmission}
          selectedSubmission={selectedSubmission}
          comments={comments}
          onCommentsChange={setComments}
          processingAction={processingAction}
        />

        {/* Snackbar */}
        <ApprovalSnackbar
          open={snackbar.open}
          message={snackbar.message}
          severity={snackbar.severity}
          onClose={handleCloseSnackbar}
        />
      </Box>
    </ThemeProvider>
  );
};

export default ApprovalManager;
